<template>
  <h1>Trin 3/7: Deres forretning</h1>
  <div>
    <div>
      <h3>Hvilken branche arbejder de?</h3>
      <Dropdown v-model="selectedIndustry" :options="industries" optionLabel="name" optionValue="name" placeholder="Vælg en branche" />
    </div>
    <div>
      <h3>Hvad er størrelsen af deres organisation?</h3>
      <div class="rb_choices organization_size">
        <div class="p-field-radiobutton">
          <RadioButton id="selfEmployed" name="selfEmployed" value="Selvstændig" v-model="sizeOfOrganization" />
          <label for="selfEmployed">Selvstændig</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton id="1-10Employees" name="1-10Employees" value="1-10 medarbejdere" v-model="sizeOfOrganization" />
          <label for="1-10Employees">1-10 medarbejdere</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton id="11-50Employees" name="11-50Employees" value="11-50 medarbejdere" v-model="sizeOfOrganization" />
          <label for="11-50Employees">11-50 medarbejdere</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton id="51-200Employees" name="51-200Employees" value="51-200 medarbejdere" v-model="sizeOfOrganization" />
          <label for="51-200Employees">51-200 medarbejdere</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton id="501-1000Employees" name="501-1000Employees" value="501-1000 medarbejdere" v-model="sizeOfOrganization" />
          <label for="501-1000Employees">501-1000 medarbejdere</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton id="1001-5000Employees" name="1001-5000Employees" value="1001-5000 medarbejdere" v-model="sizeOfOrganization" />
          <label for="1001-5000Employees">1001-5000 medarbejdere</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton id="5001-10000Employees" name="5001-10000Employees" value="5001-10.000 medarbejdere" v-model="sizeOfOrganization" />
          <label for="5001-10000Employees">5001-10.000 medarbejdere</label>
        </div>
        <div class="p-field-radiobutton">
          <RadioButton id="10000moreEmployees" name="10000moreEmployees" value="10.000 + medarbejdere" v-model="sizeOfOrganization" />
          <label for="10000moreEmployees">10.000 + medarbejdere</label>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {computed} from "vue";
import store from "@/store/store";

export default {
  name: "BusinessQuestions",
  components: {},
  setup(){
    const selectedIndustry = computed({
      get: () => store.state.industry,
      set: val => {
        store.dispatch(('setIndustry'), {industry: val})
      }
    })

    const sizeOfOrganization = computed({
      get: () => store.state.sizeOfOrganization,
      set: val => {
        store.dispatch(('setSizeOfOrganization'), {size: val})
      }
    })

    const industries = [
      {name: 'Informationsteknologi'},
      {name: 'Ingeniør og teknik'},
      {name: 'Ledelse og personale'},
      {name: 'Handel og service'},
      {name: 'Industri og håndværk'},
      {name: 'Salg og kommunikation'},
      {name: 'Undervisning'},
      {name: 'Kontor og økonomi'},
      {name: 'Social og sundhed'},
    ]

    return{
      selectedIndustry,
      industries,
      sizeOfOrganization
    }
  }
}
</script>

<style scoped>
.rb_choices.organization_size{
  flex-wrap: wrap;
}

.rb_choices.organization_size .p-field-radiobutton{
  flex: 0 0 50%;
  max-width: 50%;
}

</style>